// 
// card.scss
//

.card, .card-box {
    margin-bottom: $grid-gutter-width;
    box-shadow: $box-shadow;
    .card-drop {
        font-size: 20px;
        line-height: 0;
        color: $gray-500;
    }
}

.card-widgets {
    float: right;
    height: 16px;
    > a {
        color: inherit;
        font-size: 16px;
        display: inline-block;
        line-height: 1;
        margin-left: 7px;

        &.collapsed {
            i {
                &:before {
                    content: "\F415";
                }
            }
        }
    }
}

.card-header{
    border: none;
}


// Card title / Card Header
.card-title,
.card-header {
    margin-top: 0;
}

.card-title{
    font-size: 16px;
}

.header-title {
    font-size: 14px;
    margin: 0 0 7px 0;
    text-transform: uppercase;
}

.sub-header {
    margin-bottom: $grid-gutter-width;
    color: $gray-500;
    font-size: 13px;
}


// card footer

.card-footer{
    border-top: 1px solid $gray-200;
}


// card border

.card-border{
    .card-header{
        border-top: 3px solid ;
    }
}

// Custom card box
.card-box {
    background-color: $card-bg;
    padding: $card-spacer-x;
    box-shadow: $box-shadow;
    margin-bottom: $grid-gutter-width;
    @include border-radius($card-border-radius);
}

//Card disable loading (Custom Cards)
.card-disabled {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: $card-border-radius;
    background: rgba($black, 0.4);
    cursor: progress;

    .card-portlets-loader {
        height: 34px;
        width: 34px;
        position: absolute;
        border-top: 5px solid rgba($primary, 0.3);
        border-right: 5px solid rgba($primary, 0.3);
        border-bottom: 5px solid rgba($primary, 0.3);
        border-left: 5px solid $primary;
        border-radius: 50%;
        left: 50%;
        top: 50%;
        transform: translate(-50%);
        margin-left: - ($grid-gutter-width / 2);
        margin-top: - ($grid-gutter-width / 2);
        animation: spinner 1.1s infinite linear;
    }
}


@keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

@media (min-width: 1024px) {  
  .card-columns {column-count: 4;}
}